import { GetReportPageDataArgs, useGetReportPageDataMutation } from '@api';
import { useAppSelector } from '@hooks';
import { createSelector } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { useCallback } from 'react';
import {
    selectActiveReportPage,
    selectAppliedFilters,
    selectDateRangeComponent
} from 'src/redux/features/blueprint/bluePrintSlice';

import { assertIsDefined, getDataComponents } from 'src/templates/blueprint/utils';

export function useFetchReportPageData() {
    const dateRangeFilter = useAppSelector(selectDateRangeComponent);

    const selectMemoStart = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f) => f.id === dateRangeFilter?.id)?.value?.start
    );
    const selectMemoEnd = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f) => f.id === dateRangeFilter?.id)?.value?.end
    );
    const startDate = useAppSelector((state) => selectMemoStart(state));
    const endDate = useAppSelector((state) => selectMemoEnd(state));

    const activeReportPage = useAppSelector(selectActiveReportPage);

    const [fetchPageData] = useGetReportPageDataMutation();

    return useCallback(
        (args?: Partial<GetReportPageDataArgs>) => {
            assertIsDefined(activeReportPage);

            const filterValues = args?.filterValues ?? [
                {
                    id: dateRangeFilter?.id,
                    value: {
                        start: format(startDate, 'yyyy-MM-dd'),
                        end: format(endDate, 'yyyy-MM-dd')
                    }
                }
            ];

            const dataComponents = getDataComponents(activeReportPage.components);

            if (dataComponents?.length <= 10) {
                fetchPageData({
                    reportPageId: activeReportPage.id,
                    componentIds: dataComponents.map((c) => c.id),
                    filterValues,
                    isSharedReport: args?.isSharedReport
                });

                return;
            }

            const firstHalf = dataComponents.slice(
                0,
                Math.floor(dataComponents.length / 2)
            );

            const secondHalf = dataComponents.slice(
                Math.floor(dataComponents.length / 2)
            );

            if (firstHalf.length) {
                fetchPageData({
                    reportPageId: activeReportPage.id,
                    componentIds: firstHalf.map((c) => c.id),
                    filterValues,
                    isSharedReport: args?.isSharedReport
                });
            }

            if (secondHalf.length) {
                fetchPageData({
                    reportPageId: activeReportPage.id,
                    componentIds: secondHalf.map((c) => c.id),
                    filterValues,
                    isSharedReport: args?.isSharedReport
                });
            }
        },
        [activeReportPage, dateRangeFilter, startDate, endDate, fetchPageData]
    );
}
